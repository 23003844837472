import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Container, Row, Col } from 'react-bootstrap'

import { StaticImage } from "gatsby-plugin-image"

const SecondPage = () => (
  <Layout>
    <Seo title="Contact Us" />
    <h1 className="text-center py-3">Contact Thomas Tripolt and All Electric</h1>
    <Container>
      <Row>
        <Col lg={5}>
          <p>Main: (415) 336-7968</p>
          <p>Email: <a href="mailto:tt.allelectric@gmail.com">tt.allelectric@gmail.com</a></p>
          <p>Address: 16 Stadium Way, Kentfield, CA 94904</p>
          <p>California License: C10-525460</p>
          <hr />
          <p>Bonded & Insured</p>
          <p>Contractors Licensing Board</p>
        </Col>
        <Col lg={7}>
        <p>Thank you for your submission!</p>
        </Col>
      </Row>
      <hr />
    </Container>
    <Container>
      <Row>
        <Col lg={5}>
          <p><strong>Recommended Manufacturers</strong></p>
          <ul className="list-unstyled">
            <li><a href="http://lutron.com/">Lutron</a></li>
            <li><a href="http://leviton.com/">Leviton</a></li>
            <li><a href="http://schneider-electric.com/">Schneider-Electric</a></li>
            <li><a href="http://smarthome.com/">Smarthome</a></li>
            <li><a href="http://www.icrealtime.com/">IC Realtime</a></li>
          </ul>
          <p><strong>Recommended Lighting Store</strong></p>
          <p><a href="http://www.light-express.com/">Light Express</a></p>
        </Col>
        <Col lg={7}>
          <p>
          <StaticImage
            src="../images/light-bulb-contact.jpg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A Gatsby astronaut"
          />
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default SecondPage
